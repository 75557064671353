/**
 * Index component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */
import "assets/scss/base.scss";
import React, {useState} from "react";
import {StaticQuery, graphql} from "gatsby";
import {SVGSource} from "components/common/SvgFlags";
import HeaderOld from "components/layout/Header";
import { Header } from '../../../srcv2/components/layout/components/Header/Header';
import Footer from "components/layout/Footer";
import {withTrans} from "../../i18n/withTrans";
import {getCurrentLangKey, getLangs, getUrlForLang} from "ptz-i18n";
import Seo from "./Seo";


const Index = ({children, location, t, i18n, seo, hideLanguageSelector, hideNavigation, phoneNumber}) => {
    const [currentLanguage, setCurrentLanguage] = useState(null);
    const [langsMenu, setLangsMenu] = useState([]);
    const [isLanding, setIsLanding] = useState(false);

    return (
        <StaticQuery
            query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
              languages {
                defaultLangKey
                langs
              }
            }
          }
        }
      `}
            render={data => {
                let langKey = "ru";
                const pathPrefix = "/transfers/europe";
                if (location && !currentLanguage && "pathname" in location) {
                    setIsLanding(location.pathname.includes('/landing/'));
                    const url = location.pathname;
                    const {langs, defaultLangKey} = data.site.siteMetadata.languages;
                    const urlForCheckLang = url.includes(pathPrefix)
                        ? url.replace(pathPrefix, "")
                        : url;

                    langKey = getCurrentLangKey(langs, defaultLangKey, urlForCheckLang);

                    const homeLink = `/${langKey}`.replace(`/${defaultLangKey}/`, "/");

                    setLangsMenu(
                        getLangs(langs, langKey, getUrlForLang(homeLink, url)).map(
                            item => ({
                                ...item,
                                link: item.link
                                    .replace(`/${defaultLangKey}/`, "/")
                                    .replace(`//`, "/"),
                            })
                        )
                    );
                    setCurrentLanguage(langKey);

                    i18n.changeLanguage(langKey);

                }

                return (
                    <>
                        <Seo {...seo} lang={currentLanguage}/>
                        <div className={"page"}>
                            {isLanding ?
                                <HeaderOld langs={langsMenu} location={location} hideLanguageSelector={hideLanguageSelector}
                                                   hideNavigation={hideNavigation} phoneNumber={phoneNumber}/>
                                :
                                <Header langs={langsMenu} location={location} />
                            }
                            <main>{children}</main>
                            <Footer hideNavigation={hideNavigation} phoneNumber={phoneNumber}/>
                            <SVGSource/>
                        </div>
                    </>
                );
            }}
        />
    );
};

export default withTrans(Index);
