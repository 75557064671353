import React, { useState } from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import {ldSchemaOrganization} from "./schemaOrgJSONLD";

const activeEnv =
    process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || "development";
const isDevelopment = activeEnv === 'development';
const prefixPath = isDevelopment ? '/' : '/transfers/europe/';

function Seo(props) {
  const {
    description,
    lang,
    meta,
    keywords,
    title,
    header_title,
    countryISOid,
    og_title,
    og_description,
    og_url,
    og_locale,
    canonical,
    twitter_title,
    twitter_description,
  } = props;

  const splitKeywords = (keys = "") => (!keys.length ? [] : keys.split(","));

  return (
    <Helmet
      htmlAttributes={{
        lang: lang,
        prefix: "og: http://ogp.me/ns#",
      }}
      title={header_title || title}
      meta={[
        {
          property: `og:title`,
          content: title,
        },
        {
          name: `description`,
          content: description,
        },
        {
          name: `og:description`,
          content: description,
        },
        {
          property: `og:site_name`,
          content: "KoronaPay",
        },
        {
          property: `og:locale`,
          content: og_locale,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:url`,
          content: og_url,
        },
      ]
        .concat(
          keywords.length > 0
            ? {
                name: `keywords`,
                content: splitKeywords(keywords).join(`, `),
              }
            : []
        )
        .concat(meta)}
    >
      <script type="application/ld+json">
        {JSON.stringify(ldSchemaOrganization)}
      </script>
      {canonical && <link rel="canonical" href={canonical}/>}
      <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Roboto:wght@300;400&display=swap" as="font"/>
      <script async src="https://www.google-analytics.com/analytics.js"/>
      <script async src="https://www.googletagmanager.com/gtm.js?id=GTM-MCGQ4GR"/>
      <script async type="text/javascript" src="https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js" async />
      <script src="https://cc.cdn.civiccomputing.com/9/cookieControl-9.4.min.js" type="text/javascript"/>
      <script
          src={`${prefixPath}cookie_control.js`}
          type="text/javascript"
          defer
      />

    </Helmet>
  );
}

Seo.defaultProps = {
  lang: `ru`,
  meta: [],
  title: `KoronaPay Online Money Transfer: send and receive money safe and easy`,
  og_title: "Send money from Worldwide with KoronaPay",
  og_description: "Internation money transfer online with KoronaPay",
  og_url: `https://koronapay.com/transfers/europe/`,
  locale: "locale",
  site_name: "KoronaPay",
  header_title: null,
  keywords:
    "money, transfer, fast, system, Europe, KoronaPay, online, receive, abroad, easily, send",
  description: `Send money from Worldwide safe and easy with guaranteed fast transfer with KoronaPay.`,
};

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  keywords: PropTypes.string,
  title: PropTypes.string,
  header_title: PropTypes.string,
};

export default Seo;
