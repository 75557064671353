import type { FC } from 'react';
import type { PageProps } from 'gatsby';
import React, { useContext } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import type { ContextValue } from '@context/promo/PromoContext';
import type { LangsObj } from '@utils/interfaces/interfaces';
import { I18nSelect } from '@common/I18n/I18nSelect';
import { NavMenuMobile } from '@components/layout/components/Header/NavMenuMobile/NavMenuMobile';
import { NavMenuWeb } from '@components/layout/components/Header/NavMenuWeb/NavMenuWeb';
import { Promo } from '@components/layout/components/Header/Promo/Promo';
import { PromoContext } from '@context/promo/PromoContext';
import { LANGUAGE, LOCATIONS } from '@utils/constants';

import styles from './Header.module.scss';

import logo from '../../../../static/images/logo.svg';

export interface HeaderProps {
  langs: LangsObj[];
  location: PageProps['location'];
}

export const Header: FC<HeaderProps> = ({ langs, location }) => {
  const { isPromo, setVisiblePromo }: ContextValue = useContext(PromoContext);
  const { i18n } = useTranslation();
  // ✅ important:
  // Today promo off
  const isVisible = false && i18n.language === LANGUAGE.RUSSIA && isPromo;

  return (
    <header className={styles.header} role="banner">
      {isVisible && <Promo onClose={setVisiblePromo} />}
      <div className={classnames(styles.container, 'container')}>
        <a href={LOCATIONS.ROOT} title="Koronapay" className={styles.logo}>
          <img alt="KoronaPay Money Transfers" src={logo} />
        </a>
        <I18nSelect langs={langs} />
        <NavMenuWeb location={location} />
        <NavMenuMobile location={location} />
      </div>
    </header>
  );
};
