import type { FC } from 'react';
import React, { useState } from 'react';
import classnames from 'classnames';
import { Link } from 'gatsby';
import { useTranslation } from 'react-i18next';

import type { LangsObj } from '@utils/interfaces/interfaces';
import { LANGUAGES_TO_COUNTRY_IDS } from '@utils/constants';

import styles from './I18nSelect.module.scss';

export interface I18nSelectProps {
  langs: LangsObj[];
}

export const I18nSelect: FC<I18nSelectProps> = ({ langs }) => {
  const { i18n } = useTranslation();
  const language = i18n.language as 'ru' | 'en' | 'de';
  const [isOpened, setIsOpened] = useState(false);

  if (!langs.length) return null;

  return (
    <div className={styles.dropdown} aria-labelledby="Page language selection ">
      <button
        type="button"
        className={styles.button}
        onClick={() => setIsOpened(!isOpened)}
        aria-controls="lang-list"
        aria-haspopup="true"
        aria-expanded="false"
        id="lang-button"
      >
        {/* <span className={classnames(styles.current, styles.flag)}> */}
        {/*  <Flag id={LANGUAGES_TO_FLAG_IDS[language]} /> */}
        {/* </span> */}
        {LANGUAGES_TO_COUNTRY_IDS[language]}
        <span
          aria-hidden="true"
          className={classnames(styles.arrow, { [styles.open]: isOpened })}
        />
      </button>
      <div
        role="menu"
        id="lang-list"
        aria-labelledby="lang-button"
        className={classnames(styles.menu, { [styles.open]: isOpened })}
      >
        {langs.map((lang: LangsObj) => {
          const hideLink = i18n.language === lang.langKey;
          return (
            <Link
              key={lang.link}
              to={lang.link}
              role="menuitem"
              style={{ display: hideLink ? 'none' : 'block ' }}
              id={`button_locale_${lang.langKey}`}
              className={styles.item}
            >
              {/* <span className={styles.flag}> */}
              {/*  <Flag id={LANGUAGES_TO_FLAG_IDS[lang.langKey]} /> */}
              {/* </span> */}
              <span>{LANGUAGES_TO_COUNTRY_IDS[lang.langKey]}</span>
            </Link>
          );
        })}
      </div>
    </div>
  );
};
