const imagePathLogo = "/KoronaPay_Logo.png";

export const ldSchemaOrganization =
    {
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "KoronaPay",
        "alternateName": "KoronaPay Europe",
        "url": "https://koronapay.com/transfers/europe/",
        "logo": imagePathLogo,
        "contactPoint": [{
            "@type": "ContactPoint",
            "telephone": "+7 (495) 222 33 20",
            "contactType": "customer service",
            "availableLanguage": ["en", "Russian"]
        }, {
            "@type": "ContactPoint",
            "telephone": "+ 7 (495) 96 00 555",
            "contactType": "customer service",
            "availableLanguage": ["en", "Russian"]
        }, {
            "@type": "ContactPoint",
            "telephone": "+49 1573 5999799",
            "contactType": "customer service",
            "availableLanguage": ["en", "Russian"]
        }, {
            "@type": "ContactPoint",
            "telephone": "+372 609 3024",
            "contactType": "customer service",
            "areaServed": "EE",
            "availableLanguage": ["en", "Russian"]
        }, {
            "@type": "ContactPoint",
            "telephone": "+420 910 880 878",
            "contactType": "customer service",
            "areaServed": "CZ",
            "availableLanguage": ["en", "Russian"]
        }, {
            "@type": "ContactPoint",
            "telephone": "+358 45 4900099",
            "contactType": "customer service",
            "areaServed": "FI",
            "availableLanguage": ["en", "Russian"]
        }, {
            "@type": "ContactPoint",
            "telephone": "+46 10 551 35 15",
            "contactType": "customer service",
            "areaServed": "SE",
            "availableLanguage": ["en", "Russian"]
        }, {
            "@type": "ContactPoint",
            "telephone": "+44 7480 802011",
            "contactType": "customer service",
            "areaServed": "GB",
            "availableLanguage": ["en", "Russian"]
        }],
        "sameAs": [
            "https://www.facebook.com/koronapay.europe/",
            "https://www.instagram.com/koronapay.europe/",
            "https://www.youtube.com/channel/UCDf2lXfBXMHpm7Bfng6UXYQ",
            "https://vk.com/koronapay.europe",
            "https://ok.ru/koronapay.europe"
        ]
    }
