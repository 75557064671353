import React from 'react';
import styles from './selectLanguage.module.scss'
import cn from 'classnames'
import { Link } from 'gatsby';
import {Svg} from 'components/common/SvgFlags'
import find from 'lodash/find'

const LanguageSwitcher = {
  ru: {
      flagId: 'russia',
      name: 'RUS'
  },
  en:   {
      flagId: 'britain',
      name: 'ENG'
  },
    de:   {
        flagId: 'germany',
        name: 'GER'
    }
};

const SelectLanguage = ({langs = [], currentLangId = 'ru'}) => {
    const [isOpened, setIsOpened] = React.useState(false);

    const onButtonClick = () => setIsOpened(!isOpened);
    const showMenu = isOpened ? styles['open'] : null;

    if (!langs.length) return null;
    const currentLanguageId = find(langs, {selected: true}, {id: 'ru'}).langKey;
    const currentLanguage = LanguageSwitcher[currentLanguageId];

    return (
        <div className={styles["dropdown"]} aria-labelledby="Page language selection ">
            <button className={styles['button']}
                    onClick={onButtonClick}
                    aria-controls="lang-list"
                    aria-haspopup="true"
                    aria-expanded="false"
                    id="lang-button"
                    tabIndex="0"
            >
                    {/*<Svg id={currentLanguage.flagId}/>*/}
                  {currentLanguage.name}

                <span aria-hidden="true" className={cn(styles['arrow'], showMenu)}/>
            </button>
            <div role="menu" id="lang-list" aria-labelledby="lang-button" className={cn(styles['menu'], showMenu)}>
                {
                    langs.length !== 0
                        ? langs.map(lang => {
                            const newLink = (lang.link.includes('/help') && lang.link.match(/.+help\//gm)) ? lang.link.match(/.+help\//gm)[0] : lang.link;
                            const isHideLink = currentLangId === lang.langKey;
                            return (
                                <Link key={newLink} to={newLink} role="menuitem" style={{display: isHideLink ? 'none' : 'block '}} id={`button_locale_${lang.langKey}`} tabIndex="-1" className={styles['item']}>
                                    {/*<span className={styles['flag']}><Svg id={LanguageSwitcher[lang.langKey].flagId}/></span>*/}
                                    <span>{LanguageSwitcher[lang.langKey].name}</span>
                                </Link>
                            )
                        })
                        : <>
                            <Link to={'/'} role="menuitem" tabIndex="-1" className={styles['item']}><span className={styles['flag']}><Svg id='russia'/></span><span>RUS</span></Link>
                            <Link to={'/en'} role="menuitem" id={'button_locale_en'} tabIndex="-1" className={styles['item']}><span className={styles['flag']}><Svg id='britain'/></span><span>ENG</span></Link>
                            <Link to={'/de'} role="menuitem" id={'button_locale_de'} tabIndex="-1" className={styles['item']}><span className={styles['flag']}><Svg id='germany'/></span><span>GER</span></Link>
                        </>
                }

                {/*<Link role="menuitem" tabIndex="-1" className={styles['item']}><span className={styles['flag']}><Svg id='france'/></span><span>FRN</span></Link>*/}
                {/*<Link role="menuitem" tabIndex="-1" className={styles['item']}><span className={styles['flag']}><Svg id='germany'/></span><span>DEU</span></Link>*/}
                {/*<Link role="menuitem" tabIndex="-1" className={styles['item']}><span className={styles['flag']}><Svg id='italy'/></span><span>ITL</span></Link>*/}
                {/*<Link role="menuitem" tabIndex="-1" className={styles['item']}><span className={styles['flag']}><Svg id='spain'/></span><span>ESP</span></Link>*/}
            </div>
        </div>
    );
};

export default SelectLanguage;
