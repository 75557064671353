import type { FC } from 'react';
import type { PageProps } from 'gatsby';
import React from 'react';

import { Navigation } from '@components/layout/components/Header/Navigation/Navigation';

import styles from '../Navigation/Navigation.module.scss';

interface NavMenuProps {
  location: PageProps['location'];
}

export const NavMenuWeb: FC<NavMenuProps> = ({ location }) => (
  <nav className={styles.nav}>
    <Navigation location={location} />
    <span aria-hidden="true" className={styles.overlay} />
  </nav>
);
