import type { GatsbyLinkProps } from 'gatsby';
import { Link } from 'gatsby';
import type { FC } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { LANGUAGE } from '@utils/constants';

export type SmartLinkProps = Omit<GatsbyLinkProps<Record<string, unknown>>, 'ref'>;

export const SmartLink: FC<SmartLinkProps> = ({ to, ...props }) => {
  const { i18n } = useTranslation();
  if (i18n.language === LANGUAGE.RUSSIA) {
    return <Link {...props} to={to} />;
  }

  const link = `/${i18n.language}${to}`;
  return <Link {...props} to={link} />;
};
