import React from 'react';
import styles from './header.module.scss'
import SelectLanguage from "./selectLanguage";
import cn from 'classnames'
import {headerPageLinks} from 'config/pageLinls'
import {useTranslation} from "react-i18next";
import SmartLink from 'components/common/SmartLink'
import {promoSelector, setVisiblePromo} from "../../../state/promo";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "gatsby";
import logoKoronaPay from "../../../assets/images/KP_logo_194x58.svg"


const Header = ({langs, location, hideLanguageSelector = false, hideNavigation = false, phoneNumber}) => {
    const [isOpened, setIsOpened] = React.useState(false);
    const {isActive} = useSelector(promoSelector)
    const dispatch = useDispatch();

    const onButtonClick = () => setIsOpened(!isOpened);
    const showMenu = isOpened ? styles['open'] : null;
    const {t, i18n} = useTranslation();

    return (
        <header className={styles['header']} role="banner">
            {i18n.language === 'ru' && isActive &&
                <div className={styles['promo']}>
                    <Link to={'/blog/kak-zarabotat-300-evro-s-korona-pay-1/'} onClick={() => dispatch(setVisiblePromo(false))} title='Пригласи друга'>
                        <p className={styles['promo_text']}>Пригласи друга <br/>и получи 15€!</p>
                    </Link>
                    <button className={styles['close']} onClick={() => dispatch(setVisiblePromo(false))}/>
                </div>}
            <div className={cn(styles['container'], 'container')}>
                <a href='/?ref' title='Koronapay' className={styles['logo']}>
                    <img alt={'KoronaPay Money Transfers'} src={logoKoronaPay}/>
                </a>
                {!hideLanguageSelector && <SelectLanguage langs={langs} currentLangId={i18n.language}/>}
                {!hideNavigation && <nav className={cn(styles['nav'], showMenu)}>
                    <button onClick={onButtonClick} className={styles['ham']}><span/></button>
                    <div className={styles['nav_box']}>
                        <ul className={styles['menu']}>
                            <li className={styles['menu_item']}><a title={t('header_nav_moneyTransfers')}
                                                                   href={'https://koronapay.com/transfers/'}
                                                                   className={styles['link']}>{t('header_nav_moneyTransfers')}</a>
                            </li>
                            {headerPageLinks.map(({linkTo, title, isShow}) => {
                                const isActiveLink = location !== undefined && 'pathname' in location && location.pathname.includes(linkTo);
                                if (!isShow) return null;
                                if (linkTo === '/blog/' && i18n.language !== 'ru') return null;
                                return (
                                     <li key={linkTo.replace('/', '')} className={cn(styles['menu_item'], {})}>
                                        <SmartLink to={linkTo} title={t(title)}
                                                   className={isActiveLink ? cn(styles['link'], styles['link_active']) : styles['link']}>{t(title)}</SmartLink>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                    <span aria-hidden="true" className={styles['overlay']}/>
                </nav>}
                { !!phoneNumber && <span className={styles['phone_wrapper']}>
                    {phoneNumber}
                </span>}
            </div>
        </header>
    );
}

export default Header;
