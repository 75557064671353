import type { FC } from 'react';
import type { PageProps } from 'gatsby';
import React from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import { SmartLink } from '@common/SmartLink/SmartLink';
import { LANGUAGE, NAV_MENU_LINKS, LOCATIONS } from '@utils/constants';

import styles from './Navigation.module.scss';

interface NavMenuProps {
  location: PageProps['location'];
}

export const Navigation: FC<NavMenuProps> = ({ location }) => {
  const { t, i18n } = useTranslation();
  const navMenuLinks = NAV_MENU_LINKS.filter(
    (link) => link.path !== LOCATIONS.BLOG || i18n.language === LANGUAGE.RUSSIA
  );
  const pathPrefix = location?.pathname.includes(LOCATIONS.TRANSFERS_EUROPE)
    ? LOCATIONS.TRANSFERS_EUROPE
    : LOCATIONS.ROOT;
  const isNotRootLocation =
    location?.pathname !== `${pathPrefix}` &&
    location?.pathname !== `${pathPrefix}${i18n.language}/`;
  const isActiveTab =
    isNotRootLocation &&
    location?.pathname.includes(LOCATIONS.TRANSFERS_REDIRECT) &&
    !location?.pathname.includes(LOCATIONS.TRANSFERS_EUROPE);
  const isNotActiveTab =
    isNotRootLocation &&
    !(
      location?.pathname.includes(LOCATIONS.TRANSFERS_REDIRECT) &&
      !location?.pathname.includes(LOCATIONS.TRANSFERS_EUROPE)
    );

  return (
    <div className={styles.nav_box}>
      <ul className={styles.menu}>
        <li
          className={classnames(styles.menu_item, {
            [styles.menu_item_active]: isActiveTab,
            [styles.menu_item_not_active]: isNotActiveTab
          })}
        >
          <a
            title={t('header_nav_moneyTransfers')}
            href="https://koronapay.com/transfers/"
            className={classnames(styles.link, {
              [styles.link_active]: isActiveTab,
              [styles.link_not_active]: isNotActiveTab
            })}
          >
            {t('header_nav_moneyTransfers')}
          </a>
        </li>
        {navMenuLinks.map(({ path, title }) => (
          <li key={path} className={styles.menu_item}>
            <SmartLink
              to={path}
              title={t(title)}
              className={classnames(styles.link, {
                [styles.link_active]: isNotRootLocation && location?.pathname.includes(path),
                [styles.link_not_active]: isNotRootLocation && !location?.pathname.includes(path)
              })}
            >
              {t(title)}
            </SmartLink>
          </li>
        ))}
      </ul>
    </div>
  );
};
