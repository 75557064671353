import type { PageProps } from 'gatsby';
import type { FC } from 'react';
import React, { useState } from 'react';
import classnames from 'classnames';

import { Navigation } from '@components/layout/components/Header/Navigation/Navigation';

import styles from '../Navigation/Navigation.module.scss';

interface NavMenuProps {
  location: PageProps['location'];
}

export const NavMenuMobile: FC<NavMenuProps> = ({ location }) => {
  const [isOpened, setIsOpened] = useState(false);

  return (
    <nav className={classnames(styles.nav, { [styles.open]: isOpened })}>
      <button type="button" onClick={() => setIsOpened(!isOpened)} className={styles.ham}>
        <span />
      </button>
      {isOpened && <Navigation location={location} />}
      <span aria-hidden="true" className={styles.overlay} />
    </nav>
  );
};
